const route = {
  path: '/service',
  name: 'ServiceMain',
  component: () => import(/* webpackChunkName: "service" */ '@/views/service/Main'),
  children: [
    {
      path: 'product',
      alias: '',
      name: 'ProductService',
      component: () => import(/* webpackChunkName: "service" */ '@/views/service/ProductService'),
      prop: true
    },
    {
      path: 'premiumdb',
      name: 'PremiumDbService',
      component: () => import(/* webpackChunkName: "service" */ '@/views/service/PremiumDbService'),
      prop: true
    },
    {
      path: 'alter',
      name: 'AlterService',
      component: () => import(/* webpackChunkName: "service" */ '@/views/service/AlterService'),
      prop: true
    },
  ]
}

export default route
