import store from '@/store/index'
import axios from '@/api/axios'

export function parseApierror(error) {
  try {
    if (
      error &&
      error.hasOwnProperty("response") &&
      error.response.hasOwnProperty("data")
    ) {
      const apiError = error.response.data;
      return {
        status: apiError.status,
        statusCode: error.status,
        timestamp: apiError.timestamp,
        message: apiError.message
      }
    }
  } catch (parseError) {
    return {
      status: "INTERNAL_SERVER_ERROR",
      statusCode: 500,
      timestamp: new Date(),
      message: "Server is not responding.."
    };
  }
}

export async function completeLogout() {
  const intervalName = store.getters.getIntervalName
  if (intervalName) clearInterval(intervalName)

  const response = await axios.post("/sign/out")
  if (response.status === 200) {
    store.commit("setIsAuthenticated", false)
    store.commit("clearCurrentUser")
  }
}

export async function performLogout() {
  const intervalName = store.getters.getIntervalName
  if (intervalName) clearInterval(intervalName)
  store.commit("setIsAuthenticated", false)
  store.commit("clearCurrentUser")
}

export async function refreshTokenInternal() {
  try {
    const response = await axios.post("/sign/refresh")
    if (response.data.success === false) {
      await performLogout()
    } else if ((response.data.success === true)) {
    }
    return response.data.success

  } catch (error) {
  }
}

export async function refreshToken() {
  const response = await axios.post("/sign/refresh")
  return response.status
}

export async function getAuthenticatedUser() {
  try {
    const response = await axios.get("/member/info")
    if (response.status === 200) {
      const currentUser = response.data.data
      store.commit("setCurrentUser", currentUser)
      store.commit("setIsAuthenticated", true)

      const intervalName = setInterval(async () => {
        await refreshTokenInternal()
      }, intervalMilliSeconds)

      store.commit("setIntervalName", intervalName)
    }
  } catch (error) {
  }
}

export const intervalMilliSeconds = 1800000 // 30 minutes
