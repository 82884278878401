import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import noticePaths from './path.notice'
import qnaPaths from './path.qna'
import faqPaths from './path.faq'
import msdsPaths from './path.msds'
import servicePaths from './path.service'
import msdsProductPaths from './path.msds-product'
import profilePaths from './path.profile'
import pointPaths from './path.mypage'
import { refreshTokenInternal } from '@/api/auth-utils'

Vue.use(VueRouter)

// 다음페이지 이동시 스크롤이 맨위로 향하게 합니다.
const router = new VueRouter({
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Splash',
      component: () => import(/* webpackChunkName: "splash" */ '@/views/Main'),
      meta: {
        layout: 'NoneLayout'
      }
    },
    {
      path: '/dashboard',
      name: 'DashBoard',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/DashBoard')
    },
    {
      path: '/intro',
      name: 'Intro',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Intro'),
      meta: {
        layout: 'IntroLayout',
        step: ['intro', 'Intro']
      }
    },
    {
      path: '/logout',
      name: 'Logout',
      beforeEnter: (to, from, next) => {
        store.dispatch('main')
        next({
          path: '/',
          replace: true,
        })
      }
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "404" */ '@/views/404'),
      meta: {
        layout: 'NoneLayout'
      },
    }
  ]
})

router.addRoute(noticePaths)
router.addRoute(qnaPaths)
router.addRoute(faqPaths)
router.addRoute(msdsPaths)
router.addRoute(msdsProductPaths)
router.addRoute(profilePaths)
router.addRoute(servicePaths)
router.addRoute(pointPaths)

router.beforeEach(async (to, from, next) => {
  if (to.path !== '/' && !store.getters.getIsAuthenticated) {
    try {
      console.log("refresh")
      if (!await refreshTokenInternal()) {
        console.log("fail and next /")
        next('/')
      } else { next() }
    } catch (error) {
      console.log(error)
      next('/')
    }
  } else {
/*    if (to.path === '/' && store.getters.getIsAuthenticated) {
      next('/dashboard')
    }else {
      next()
    }*/
    next()
  }
})

export default router
