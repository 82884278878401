<template>
  <div id="app">
    <base-layout>
      <router-view/>
    </base-layout>
  </div>
</template>

<script>
  import BaseLayout from '@/layouts/BaseLayout'

  export default {
  name: 'App',
  components: { BaseLayout },
}
</script>
<style lang="scss">
@import 'assets/css/style.css';
</style>
