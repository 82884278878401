const state = {
  isFinalized: false,
  hasMsds: false,
  currentMatter: {
    productMatterSeq: '',
    msds: '',
  },
}

const getters = {
  getIsFinalized (state) {
    return state.isFinalized
  },
  getHasMsds (state) {
    return state.hasMsds
  },
  getCurrentMatter (state) {
    return state.currentMatter
  },
}

const mutations = {
  setIsFinalized (state, payload) {
    state.isFinalized = payload
  },
  setHasMsds (state, payload) {
    state.hasMsds = payload
  },
  setCurrentMatter (state, payload) {
    state.currentMatter.productMatterSeq = payload[0]
    state.currentMatter.msds = payload[1]
  },
  clearCurrentMatter (state) {
    state.currentMatter.productMatterSeq = ''
    state.currentMatter.msds = ''
  },
}

const actions = {
  async getCurrentMatter ({ commit, dispatch }) {},
  setCurrentMatter ({commit}, payload) {
    commit('setCurrentMatter', payload)
  },
  setHasMsds ({ commit , dispatch }, payload) {
    commit('setHasMsds', payload)
  },
  setIsFinalized ({ commit , dispatch }, payload) {
    commit('setIsFinalized', payload)
  }
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
}
