'use strict'

import axios from 'axios'
import {
  refreshTokenInternal,
} from '@/api/auth-utils'

const isHandlerEnabled = (config = {}) => {
  return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
    ? false : true
}

const _axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  timeout: 90000
})

_axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
}

const requestHandler = request => {
  return request
}

const errorHandler = async error => {
    if (error.response) {
      if (error.response.status === 401) {
        if ( await refreshTokenInternal()) {
          return  Promise.resolve(_axios(error.config))
        } else {
          //window.location.replace('/')
          return Promise.reject(new Error('로그인이 만료 되었습니다.'))
        }
      }
    }
  return Promise.reject({ ...error })
}

const successHandler = response => {
  return response
}

_axios.interceptors.request.use(request => requestHandler(request));

_axios.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

export default _axios
