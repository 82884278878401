import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import authStore from './module/authStore'
import productMatterStore from './module/productMatterStore'

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    authStore,
    productMatterStore
  },
  plugins: [
    createPersistedState({
        paths: ['authStore', 'productMatterStore']
    })
  ]
})
