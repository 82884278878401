const route = {
  path: '/qna',
  name: 'QnaMain',
  component: () => import(/* webpackChunkName: "qna" */ '@/views/qna/Main'),
  children: [
    {
      path: 'list',
      alias: '',
      name: 'QnaList',
      component: () => import(/* webpackChunkName: "qna" */ '@/views/qna/List'),
      prop: true
    },
    {
      path: ':seq(\\d+)',
      name: 'QnaView',
      component: () => import(/* webpackChunkName: "qna" */ '@/views/qna/View'),
      prop: true
    },
    {
      path: 'write',
      name: 'QnaWrite',
      component: () => import(/* webpackChunkName: "qna" */ '@/views/qna/Write'),
      prop: true
    },
    {
      path: 'mod/:seq(\\d+)',
      name: 'QnaModify',
      component: () => import(/* webpackChunkName: "qna" */ '@/views/qna/Modify'),
      prop: true
    },
    {
      path: 'error',
      name: 'QnaError',
      component: () => import(/* webpackChunkName: "qna" */ '@/views/404'),
      prop: true
    }
  ]
}

export default route
