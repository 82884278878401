import {confirmed, email, integer, length, max, min, min_value, numeric, required, regex, double} from 'vee-validate/dist/rules'
import {extend, localize} from 'vee-validate'
import ko from 'vee-validate/dist/locale/ko.json'

localize('ko', ko)

extend('required', {
  ...required,
  // message: 'This field is required1'
})

extend('email', {
  ...email,
  // message: 'This field must be a valid email'
})

extend('confirmed', {
  ...confirmed,
  // message: 'This field confirmation does not match'
})

extend('length', {
  ...length,
})

extend('numeric', {
  ...numeric,
})

extend('integer', {
  ...integer,
})

extend('min', {
  ...min,
})

extend('min_value', {
  ...min_value,
})

extend('max', {
  ...max,
})

extend('regex', {
  ...regex,
})

extend('double', {
  ...double,
  message: '유효한 실수를 입력해주세요.'
})

extend('id', (value) => {
  if (!/(^[a-zA-Z])/.test(value)) {
    return '아이디의 첫글자는 영문으로 입력해주세요.'
  }
  if (!/^[a-z0-9_-]{4,20}$/.test(value)) {
    return '아이디는 (영문소문자, 숫자, -,_)의 4~20자리로 입력해주세요.'
  }
  return true
})

extend('password', (value) => {
  if (!/^.*(?=^.{8,15}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/.test(value)) {
    return "비밀번호를 올바르게 입력해주세요."
  }
  return true;
})

extend('phone', (value) => {
  if (!/^([0-9]{2,3})-([0-9]{3,4})-([0-9]{4})$/.test(value)) {
    return "'-' 을 포함한 번호를 올바르게 입력해주세요."
  }
  return true;
})

extend('license', (value) => {
  if (!/^([0-9]{3})-([0-9]{2})-([0-9]{5})$/.test(value)) {
    return "'-' 을 포함한 사업자 번호 10자리를 올바르게 입력해주세요."
  }
  return true;
})

extend('cas-number', (value) => {
  if (value === '-') {
    return true;
  } else if (!/^([0-9]{1,7})-([0-9]{2})-([0-9])$/.test(value)) {
    return "유효하지 않은 CAS No."
  }
  return true;
})

// 양수O, 음수X, 소수점O
extend('positive-number', {
  validate: value => {
    return /^\d*[.]{0,1}\d*$/.test(value);
  },
    // message property:
    message: "양수를 입력해주세요."
})

// 양수O, 음수O, 소수점O
extend('number',  {
  validate: value => {
    return /^-?([0]{1}\.{1}[0-9]+|[1-9]{1}[0-9]*\.{1}[0-9]+|[0-9]+|0)$/.test(value);
  },
    // message property:
    message: '숫자를 입력해주세요.',
})

extend('percent-limit', (value) => {
  if (!/^(\b(0|[1-9][0-9]?|100)\b)*$/.test(value)) {
    return "물질의 함유량을 100%를 초과할 수 없습니다."
  }
  return true;
})

extend('custom-percent-limit', (value) => {
  if (parseFloat(value)>100.0) {
    return "물질의 함유량을 100%를 초과할 수 없습니다."
  }
  return true;
})

extend('custom-percent-zero', (value) => {
  if (0 === parseFloat(value)) {
    return "물질의 함유량은 0초과여야 합니다."
  }
  return true;
})

extend('blank',(value) =>{
  if(/^([ \t\r\n\v\f])*$/.test(value)){
    return "공백을 제외한 문자와 숫자만 입력해주세요.";
  }
  return true
})
