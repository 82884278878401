const state = {
  isAuthenticated: false,
  currentUser: {
    memberSeq: '',
    memberId: '',
    level: '',
    role: ''
  },
  intervalName: ''
}

const getters = {
  getIsAuthenticated(state) {
    return state.isAuthenticated
  },
  getCurrentUser(state) {
    return state.currentUser
  },
  getIntervalName(state) {
    return state.intervalName
  }
}

const mutations = {
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
  },
  setCurrentUser(state, currentUser) {
    state.currentUser.memberId = currentUser.memberId
    state.currentUser.memberSeq = currentUser.memberSeq
    state.currentUser.role = currentUser.role
    state.currentUser.level = currentUser.level
  },
  setIntervalName(state, intervalName) {
    state.intervalName = intervalName
  },
  clearCurrentUser(state) {
    state.currentUser.memberId = ""
    state.currentUser.memberSeq = ""
    state.currentUser.role = ""
    state.currentUser.level = ""
  }
}

const actions = {
  async login({ commit, dispatch }, loginData) {},
  async getCurrentUser({ commit, dispatch }) {},
  async refresh({ commit, dispatch, getters }) {},
  async logout({ commit, dispatch, getters }) {}
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions
}
