const route = {
  path: '/msds-product',
  name: 'MsdsProductMain',
  component: () => import(/* webpackChunkName: "msds-product" */ '@/views/msds-product/Main'),
  meta: {
    layout: 'DefaultLayout'
  },
  children: [
    {
      path: 'detail/:seq(\\d+)',
      name: 'MsdsProductDetail',
      component: () => import(/* webpackChunkName: "msds-product" */ '@/views/msds-product/MsdsProductDetail'),
      prop: true,
      meta: {
        step: ['MSDS', 'MSDS 제품 정보']
      },
      children: [
        {
          path: 'step1',
          alias: '',
          name: 'MsdsProductStep1',
          component: () => import(/* webpackChunkName: "msds-product1" */ '@/views/msds-product/MsdsProductStep1'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','1. 화학제품과 회사에 관한 정보']
          },
        },
        {
          path: 'step2',
          name: 'MsdsProductStep2',
          component: () => import(/* webpackChunkName: "msds-product2" */ '@/views/msds-product/MsdsProductStep2'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','2. 유해성 · 위험성']
          },
        },
        {
          path: 'step3',
          name: 'MsdsProductStep3',
          component: () => import(/* webpackChunkName: "msds-product3" */ '@/views/msds-product/MsdsProductStep3'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','3. 구성성분 명칭 · 함유량']
          },
        },
        {
          path: 'step4',
          name: 'MsdsProductStep4',
          component: () => import(/* webpackChunkName: "msds-product4" */ '@/views/msds-product/MsdsProductStep4'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','4. 응급조치 요령']
          },
        },
        {
          path: 'step5',
          name: 'MsdsProductStep5',
          component: () => import(/* webpackChunkName: "msds-product5" */ '@/views/msds-product/MsdsProductStep5'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','5. 폭발 · 화재시 대처방법']
          },
        },
        {
          path: 'step6',
          name: 'MsdsProductStep6',
          component: () => import(/* webpackChunkName: "msds-product6" */ '@/views/msds-product/MsdsProductStep6'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','6. 누출 사고 시 대처방법']
          },
        },
        {
          path: 'step7',
          name: 'MsdsProductStep7',
          component: () => import(/* webpackChunkName: "msds-product7" */ '@/views/msds-product/MsdsProductStep7'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','7. 취급 및 저장방법']
          },
        },
        {
          path: 'step8',
          name: 'MsdsProductStep8',
          component: () => import(/* webpackChunkName: "msds-product8" */ '@/views/msds-product/MsdsProductStep8'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','8. 노출방지 및 개인보호구']
          },
        },
        {
          path: 'step9',
          name: 'MsdsProductStep9',
          component: () => import(/* webpackChunkName: "msds-product9" */ '@/views/msds-product/MsdsProductStep9'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','9. 물리화학적 특성']
          },
        },
        {
          path: 'step10',
          name: 'MsdsProductStep10',
          component: () => import(/* webpackChunkName: "msds-product10" */ '@/views/msds-product/MsdsProductStep10'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','10. 안정성 및 반응성']
          },
        },
        {
          path: 'step11',
          name: 'MsdsProductStep11',
          component: () => import(/* webpackChunkName: "msds-product11" */ '@/views/msds-product/MsdsProductStep11'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','11. 독성에 관한 정보']
          },
        },
        {
          path: 'step12',
          name: 'MsdsProductStep12',
          component: () => import(/* webpackChunkName: "msds-product12" */ '@/views/msds-product/MsdsProductStep12'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','12. 환경에 미치는 영향']
          },
        },
        {
          path: 'step13',
          name: 'MsdsProductStep13',
          component: () => import(/* webpackChunkName: "msds-product13" */ '@/views/msds-product/MsdsProductStep13'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','13. 폐기 시 주의사항']
          },
        },
        {
          path: 'step14',
          name: 'MsdsProductStep14',
          component: () => import(/* webpackChunkName: "msds-product14" */ '@/views/msds-product/MsdsProductStep14'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','14. 운송에 필요한 정보']
          },
        },
        {
          path: 'step15',
          name: 'MsdsProductStep15',
          component: () => import(/* webpackChunkName: "msds-product15" */ '@/views/msds-product/MsdsProductStep15'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','15. 법적 규제현황']
          },
        },
        {
          path: 'step16',
          name: 'MsdsProductStep16',
          component: () => import(/* webpackChunkName: "msds-product16" */ '@/views/msds-product/MsdsProductStep16'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 제품 정보','16. 그 밖의 참고사항']
          },
        },
      ]
    }
  ]
}

export default route
