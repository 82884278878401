const addRoute =
  {
    path: '/mypage',
    name: 'MyPageMain',
    component: () => import(/* webpackChunkName: "mypage" */ '@/views/mypage/Main'),
    children: [
      {
        path: 'matter-library',
        name: 'MatterLibrary',
        component: () => import(/* webpackChunkName: "matter-library" */ '@/views/mypage/MatterLibrary'),
        prop: true
      },
      {
        path: 'supplier-info',
        name: 'SupplierInfo',
        component: () => import(/* webpackChunkName: "supplier-info" */ '@/views/mypage/SupplierInfo'),
        prop: true
      },
      {
        path: 'point',
        name: 'PointPage',
        component: () => import(/* webpackChunkName: "pointPage" */ '@/views/mypage/PointPage'),
        prop: true
      },
    ]
  }

export default addRoute
