const route = {
  path: '/msds',
  name: 'MsdsMain',
  component: () => import(/* webpackChunkName: "msds" */ '@/views/msds/Main'),
  meta: {
    layout: 'DefaultLayout'
  },
  children: [
    {
      path: 'list',
      alias: '',
      name: 'MsdsList',
      component: () => import(/* webpackChunkName: "msds" */ '@/views/msds/List'),
      prop: true
    },
    {
      path: ':seq(\\d+)',
      name: 'ProductView',
      component: () => import(/* webpackChunkName: "msds" */ '@/views/msds/ProductView'),
      prop: true
    },
    {
      path: 'detail/:seq(\\d+)',
      name: 'MsdsDetail',
      component: () => import(/* webpackChunkName: "msds" */ '@/views/msds/MsdsDetail'),
      prop: true,
      meta: {
        step: ['MSDS', 'MSDS 물질 정보']
      },
      children: [
        {
          path: 'step1',
          alias: '',
          name: 'MsdsStep1',
          component: () => import(/* webpackChunkName: "msds1" */ '@/views/msds/MsdsStep1'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','1. 화학제품과 회사에 관한 정보']
          },
        },
        {
          path: 'step2',
          name: 'MsdsStep2',
          component: () => import(/* webpackChunkName: "msds2" */ '@/views/msds/MsdsStep2'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','2. 유해성 · 위험성']
          },
        },
        {
          path: 'step3',
          name: 'MsdsStep3',
          component: () => import(/* webpackChunkName: "msds3" */ '@/views/msds/MsdsStep3'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','3. 구성성분 명칭 · 함유량']
          },
        },
        {
          path: 'step4',
          name: 'MsdsStep4',
          component: () => import(/* webpackChunkName: "msds4" */ '@/views/msds/MsdsStep4'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','4. 응급조치 요령']
          },
        },
        {
          path: 'step5',
          name: 'MsdsStep5',
          component: () => import(/* webpackChunkName: "msds5" */ '@/views/msds/MsdsStep5'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','5. 폭발 · 화재시 대처방법']
          },
        },
        {
          path: 'step6',
          name: 'MsdsStep6',
          component: () => import(/* webpackChunkName: "msds6" */ '@/views/msds/MsdsStep6'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','6. 누출 사고 시 대처방법']
          },
        },
        {
          path: 'step7',
          name: 'MsdsStep7',
          component: () => import(/* webpackChunkName: "msds7" */ '@/views/msds/MsdsStep7'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','7. 취급 및 저장방법']
          },
        },
        {
          path: 'step8',
          name: 'MsdsStep8',
          component: () => import(/* webpackChunkName: "msds8" */ '@/views/msds/MsdsStep8'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','8. 노출방지 및 개인보호구']
          },
        },
        {
          path: 'step9',
          name: 'MsdsStep9',
          component: () => import(/* webpackChunkName: "msds9" */ '@/views/msds/MsdsStep9'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','9. 물리화학적 특성']
          },
        },
        {
          path: 'step10',
          name: 'MsdsStep10',
          component: () => import(/* webpackChunkName: "msds10" */ '@/views/msds/MsdsStep10'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','10. 안정성 및 반응성']
          },
        },
        {
          path: 'step11',
          name: 'MsdsStep11',
          component: () => import(/* webpackChunkName: "msds11" */ '@/views/msds/MsdsStep11'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','11. 독성에 관한 정보']
          },
        },
        {
          path: 'step12',
          name: 'MsdsStep12',
          component: () => import(/* webpackChunkName: "msds12" */ '@/views/msds/MsdsStep12'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','12. 환경에 미치는 영향']
          },
        },
        {
          path: 'step13',
          name: 'MsdsStep13',
          component: () => import(/* webpackChunkName: "msds13" */ '@/views/msds/MsdsStep13'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','13. 폐기 시 주의사항']
          },
        },
        {
          path: 'step14',
          name: 'MsdsStep14',
          component: () => import(/* webpackChunkName: "msds14" */ '@/views/msds/MsdsStep14'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','14. 운송에 필요한 정보']
          },
        },
        {
          path: 'step15',
          name: 'MsdsStep15',
          component: () => import(/* webpackChunkName: "msds15" */ '@/views/msds/MsdsStep15'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','15. 법적 규제현황']
          },
        },
        {
          path: 'step16',
          name: 'MsdsStep16',
          component: () => import(/* webpackChunkName: "msds16" */ '@/views/msds/MsdsStep16'),
          prop: true,
          meta: {
            step: ['MSDS', 'MSDS 물질 정보','16. 그 밖의 참고사항']
          },
        }
      ]
    },{
      path: '*',
      alias: 'error',
      name: 'Error',
      component: () => import(/* webpackChunkName: "qna" */ '@/views/404'),
      prop: true
    }
  ]
}

export default route
