const route = {
  path: '/faq',
  name: 'FaqMain',
  component: () => import(/* webpackChunkName: "faq" */ '@/views/faq/Main'),
  children: [
    {
      path: 'list',
      alias: '',
      name: 'FaqList',
      component: () => import(/* webpackChunkName: "faq" */ '@/views/faq/List'),
      prop: true
    },
    // {
    //   path: ':seq(\\d+)',
    //   name: 'FaqView',
    //   component: () => import(/* webpackChunkName: "faq" */ '@/views/faq/View'),
    //   prop: true
    // },
  ]
}

export default route
