const route = {
  path: '/notice',
  name: 'NoticeMain',
  component: () => import(/* webpackChunkName: "notice" */ '@/views/notice/Main'),
  children: [
    {
      path: 'list',
      alias: '',
      name: 'NoticeList',
      component: () => import(/* webpackChunkName: "notice" */ '@/views/notice/List'),
      prop: true
    },
    {
      path: ':seq(\\d+)',
      name: 'NoticeView',
      component: () => import(/* webpackChunkName: "notice" */ '@/views/notice/View'),
      prop: true
    },
    {
      path: 'error',
      name: 'NoticeError',
      component: () => import(/* webpackChunkName: "qna" */ '@/views/404'),
      prop: true
    }
  ]
}

export default route
